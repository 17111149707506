<template>
  <el-image class="custom-image" :src="src" :fit="fit" :lazy="lazy" :alt="alt">
    <div slot="placeholder" class="image-slot">
      <i class="el-icon-picture-outline"></i>
    </div>
  </el-image>
</template>

<script>
export default {
	name: "customImage",
  props: {
		fit: {
			type: String,
      default: 'contain'
    },
    src: {
	    type: String,
	    default: ''
    },
    lazy: {
			type: Boolean,
      default: false
    },
    alt: {
			type: String,
      default: ''
    }
  },

}
</script>

<style lang="scss" scoped>
  .custom-image {
    .image-slot {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height:100%;
      background-color: #f5f7fa;

      .el-icon-picture-outline {
        font-size: 24px;
        color: #e6e6e6;
      }
    }

    ::v-deep .el-image__inner {
      display: block;
    }
  }
</style>
