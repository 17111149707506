<template>
  <footer class="footer">
    <div class="footer-main">
      <div class="footer-about">
        <div class="footer-contact-us">
          <div class="footer-about-title">联系我们</div>
          <ul class="contact-us-list">
            <li class="contact-us-item">
              <span class="us-title">服务热线：</span>
              <span class="us-value">020-83515031</span>
            </li>
            <li class="contact-us-item">
              <span class="us-title">联系地址：</span>
              <span class="us-value">广东省广州市越秀区东风中路389号壬丰商务大厦23楼</span>
            </li>
          </ul>
        </div>

        <div class="footer-friendly-link">
          <div class="footer-about-title">友情链接</div>
          <ul class="footer-friendly-list">
            <li class="footer-friendly-item">
              <a href="http://smzt.gd.gov.cn/" target="_blank">广东省民政厅</a>
            </li>
            <li class="footer-friendly-item">
              <a href="http://edu.gd.gov.cn/" target="_blank">广东省教育厅</a>
            </li>
            <li class="footer-friendly-item">
              <a href="http://hrss.gd.gov.cn/" target="_blank">广东省人力资源与社会保障厅</a>
            </li>
          </ul>
        </div>

        <div class="qr-code-info">
          <img class="qr-code-image" src="@/assets/images/wechat-mini-program2.jpg" alt="微信小程序二维码" />
          <span class="info-name">微信小程序</span>
        </div>

        <div class="qr-code-info">
          <img class="qr-code-image" src="@/assets/images/wechat-official-account2.jpg" alt="微信公众号二维码" />
          <span class="info-name">微信公众号</span>
        </div>
      </div>

      <div class="reference-info">
        <span>粤人协</span>
        <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备18106532号-3</a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
	name: "common-footer",

}
</script>

<style lang="scss" scoped>
  .footer {
    width: 100%;
    min-width: $max-width;
    height: 310px;
    background-color: #202329;
    .footer-main {
      width: $max-width;
      height: 100%;
      margin: 0 auto;
      padding: 40px 55px 0 0;

      .footer-about {
        @include flex();

        .footer-about-title {
          height: 20px;
          margin-bottom: 34px;
          padding-left: 10px;
          line-height: 20px;
          font-size: 20px;
          font-weight: bold;
          color: #FFF;
          border-left: 4px solid $theme;
        }

        .contact-us-list {
          .contact-us-item {
            @include flex();
            font-size: 16px;
            color: #FFF;

            & + .contact-us-item {
              margin-top: 30px;
            }

            .us-title {
              display: block;
              width: 6em;
              margin-right: 10px;
              white-space: nowrap;
            }
            .us-value {
              display: block;
              width: 268px;
            }
          }
        }

        .footer-friendly-link {
          margin-left: 80px;
        }

        .footer-friendly-list {
          .footer-friendly-item {
            font-size: 16px;
            color: #FFF;
            white-space: nowrap;
            & + .footer-friendly-item {
              margin-top: 30px;
            }
          }
        }

        .qr-code-info {
          @include flexDirection(center, center);
          margin-left: 120px;
          .qr-code-image {
            @include image(120px, 120px);
          }
          .info-name {
            margin-top: 10px;
            font-size: 14px;
            color: #FFF;
          }
        }
      }

      .reference-info {
        margin-top: 54px;
        text-align: center;
        font-size: 14px;
        color: #999;
        white-space: pre-wrap;
      }
    }
  }
</style>
