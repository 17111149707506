const getters = {
	token: state => state.user.token,
	admin_name: state => state.user.admin_name,
	photo_url: state => state.user.photo_url,
	phone: state => state.user.phone,
	post_name: state => state.user.post_name,
	join_in_status: state => state.user.join_in_status,
	status: state => state.user.status,
	enterprise_id: state => state.user.enterprise_id,
	enterprise_name: state => state.user.enterprise_name,
	enterprise_logo_url: state => state.user.enterprise_logo_url,
	enterprise_address: state => state.user.enterprise_address,
	enterprise_province: state => state.user.enterprise_province,
	enterprise_city: state => state.user.enterprise_city,
	enterprise_area: state => state.user.enterprise_area,
	user_province: state => state.user.user_province,
	user_city: state => state.user.user_city,
	user_area: state => state.user.user_area,
	is_logout_enterprise: state => state.user.is_logout_enterprise,
	user_name: state => state.user.user_name,
	birthday: state => state.user.birthday,
	sex_name: state => state.user.sex_name,
	sex_id: state => state.user.sex_id,
	education_id: state => state.user.education_id,
	education_name: state => state.user.education_name,
	posts_type_id: state => state.user.posts_type_id,
	posts_type_name: state => state.user.posts_type_name,
	is_social_organization: state => state.user.is_social_organization,
	// photo_id: state => state.user.photo_id,
	// reg_ip: state => state.user.reg_ip,
	// reg_time: state => state.user.reg_time,
	// email: state => state.user.email,
	// is_enterprise_admin: state => state.user.is_enterprise_admin,
}

export default getters
