import {Auth} from '@/utils/auth'
import router from '@/router'
import {
	loginByPhone,
	getEntUserInfo,
	logout,
	getUserInfo,
	getEnterpriseDetail, getUserRegionByIp,
} from '@/api'
import { Notification } from 'element-ui'

const getDefaultState = () => {
	return {
		token: Auth.getToken(),
		user_name: '',
		admin_name: '',
		sex: '',
		phone: '',
		birthday: '',
		education_id: '',
		education_name: '',
		posts_type_id: '',
		posts_type_name: '',
		photo_url: '',
		post_name: '',
		user_province: '',
		user_city: window.localStorage.getItem('city'),
		user_area: '',
		join_in_status: 0,
		enterprise_id: 0,
		enterprise_name: '',
		enterprise_logo_url: '',
		enterprise_address: '',
		enterprise_province: '',
		enterprise_city: '',
		enterprise_area: '',
		is_logout_enterprise: 0,
		is_social_organization: 0,
	}
}
const state = getDefaultState()

const mutations = {
	// 设置token
	SET_TOKEN: (state) => {
		state.token = Auth.getToken()
	},
	// 设置普通用户的姓名
	SET_USER_NAME: (state, user_name) => {
		state.user_name = user_name
	},
	// 设置普通用户的出生日期
	SET_USER_BIRTHDAY: (state, birthday) => {
		state.birthday = birthday
	},
	// 设置普通用户的性别id
	SET_USER_SEX_ID: (state, sex_id) => {
		state.sex_id = sex_id
	},
	// 设置普通用户的性别
	SET_USER_SEX_NAME: (state, sex_name) => {
		state.sex_name = sex_name
	},
	// 设置普通用户的头像
	SET_PHOTO_URL: (state, photo_url) => {
		state.photo_url = photo_url
	},
	// 设置普通用户联系方式
	SET_PHONE: (state, phone) => {
		state.phone = phone
	},
	// 设置普通用户的学历名称id
	SET_EDUCATION_ID: (state, education_id) => {
		state.education_id = education_id
	},
	// 设置普通用户的学历名称
	SET_EDUCATION_NAME: (state, education_name) => {
		state.education_name = education_name
	},
	// 设置普通用户的岗位类型id
	SET_EXPECT_POSTS_TYPE_ID: (state, posts_type_id) => {
		state.posts_type_id = posts_type_id
	},
	// 设置普通用户的岗位类型名称
	SET_EXPECT_POSTS_TYPE_NAME: (state, posts_type_name) => {
		state.posts_type_name = posts_type_name
	},
	// 设置普通用户所在省
	SET_USER_PROVINCE: (state, province) => {
		state.user_province = province || ''
	},
	// 设置普通用户所在市
	SET_USER_CITY: (state, city) => {
		state.user_city = city || ''
		window.localStorage.setItem('city', city)
	},
	// 设置普通用户所在省
	SET_USER_AREA: (state, area) => {
		state.user_area = area || ''
	},


	// 设置企业用户名
	SET_ADMIN_NAME: (state, admin_name) => {
		state.admin_name = admin_name
	},
	// 设置企业用户入驻状态
	SET_JOIN_IN_STATUS: (state, join_in_status) => {
		state.join_in_status = join_in_status
	},
	// 设置企业用户注销状态
	SET_LOGOUT_STATUS: (state, is_logout_enterprise) => {
		state.is_logout_enterprise = is_logout_enterprise
	},
	// 设置企业用户职位名称
	SET_POST_NAME: (state, post_name) => {
		state.post_name = post_name
	},
	// 设置企业用户所属企业id
	SET_ENTERPRISE_ID: (state, enterprise_id) => {
		state.enterprise_id = enterprise_id
	},
	// 设置企业用户所在企业名称
	SET_ENTERPRISE_NAME: (state, enterprise_name) => {
		state.enterprise_name = enterprise_name
	},
	// 设置企业用户所属企业logo
	SET_ENTERPRISE_LOGO_URL: (state, enterprise_logo_url) => {
		state.enterprise_logo_url = enterprise_logo_url
	},

	// 设置企业所在位置
	SET_ENTERPRISE_ADDRESS: (state, address) => {
		state.enterprise_address = address
	},
	// 设置企业所在省
	SET_ENTERPRISE_PROVINCE: (state, province) => {
		state.enterprise_province = province
	},
	// 设置企业所在市
	SET_ENTERPRISE_CITY: (state, city) => {
		state.enterprise_city = city
	},
	// 设置企业所在区
	SET_ENTERPRISE_AREA: (state, area) => {
		state.enterprise_area = area
	},
	// 企业用户是否为社会组织人员
	SET_IS_SOCIAL_ORGANIZATION: (state, is_social_organization) => {
		state.is_social_organization = is_social_organization
	}
}

const actions = {

	// 普通登录
	login({ commit, state }, params) {
		return new Promise((resolve, reject) => {
			loginByPhone(params).then(async({ data }) => {
				if (data.level === 'success') {
					Auth.setToken(data.data)
					await this.dispatch('user/getEntUserInfo')
					await this.dispatch('user/getUserInfo')
					await this.dispatch('user/getEnterpriseDetail')
					Notification.success(data.message)
					resolve(data.data)
				} else {
					Notification.warning(data.message)
					reject(data.message)
					console.error(data.message)
				}
			}).catch(error => {
				reject(error)
			})
		})
	},

	// 获取管理员信息
	getUserInfo() {
		return new Promise((resolve, reject) => {
			getUserInfo().then(({data}) => {
				if (data.level === 'success') {
					this.commit('user/SET_TOKEN')
					this.commit('user/SET_PHOTO_URL', data.data.photo_url || '')
					this.commit('user/SET_USER_NAME', data.data.realname || '')
					this.commit('user/SET_USER_BIRTHDAY', data.data.birthday || '')
					this.commit('user/SET_USER_SEX_ID', data.data.sex || '')
					this.commit('user/SET_USER_SEX_NAME', data.data.sex_name || '')
					this.commit('user/SET_EDUCATION_ID', Number(data.data.education_id) || '')
					this.commit('user/SET_EDUCATION_NAME', data.data.education_name || '')
					this.commit('user/SET_EXPECT_POSTS_TYPE_ID', data.data.expect_posts_type_id || '')
					this.commit('user/SET_EXPECT_POSTS_TYPE_NAME', data.data.expect_posts_type_name || '')
					this.commit('user/SET_PHONE', data.data.phone || '')
					resolve(data.data)
				}
			}).catch(error => {
				reject(error)
			})
		})
	},

	// 获取企业用户信息
	getEntUserInfo() {
		return new Promise((resolve, reject) => {
			getEntUserInfo().then(({data}) => {
				if (data.level === 'success') {
					// this.dispatch('user/getUserRegionByIp')
					this.commit('user/SET_TOKEN')
					this.commit('user/SET_LOGOUT_STATUS', data.data.is_logout_enterprise || '')
					this.commit('user/SET_PHONE', data.data.phone || '')
					this.commit('user/SET_ADMIN_NAME', data.data.realname || '')
					this.commit('user/SET_JOIN_IN_STATUS', data.data.join_in_status || 0)
					this.commit('user/SET_POST_NAME', data.data.post || '')
					this.commit('user/SET_ENTERPRISE_ID', data.data.enterprise_id || 0)
					this.commit('user/SET_ENTERPRISE_NAME', data.data.enterprise_name || '')
					this.commit('user/SET_ENTERPRISE_LOGO_URL', data.data.enterprise_logo_url || '')
					this.commit('user/SET_IS_SOCIAL_ORGANIZATION', data.data.is_social_organization || 0)
					resolve(data.data)
				}
			}).catch(error => {
				reject(error)
			})
		})
	},

	// 获取企业详情信息
	getEnterpriseDetail({state}) {
		if (state.enterprise_id === 0) return
		return new Promise((resolve, reject) => {
			const params = {id: state.enterprise_id}
			getEnterpriseDetail(params).then((res) => {
				const {data, level} = res.data
				if (level === 'success') {
					this.commit('user/SET_ENTERPRISE_ADDRESS', data.address || '')
					this.commit('user/SET_ENTERPRISE_PROVINCE', data.province || '')
					this.commit('user/SET_ENTERPRISE_CITY', data.city || '')
					this.commit('user/SET_ENTERPRISE_AREA', data.area || '')
				}
				resolve(res.data)
			}).catch(err => {
				console.error(err)
				reject(err)
			})
		})
	},

	// 退出登录
	logout({ commit, state }) {
		return new Promise((resolve, reject) => {
			logout().then(() => {
				Auth.removeToken()
				this.commit('user/SET_TOKEN')
				this.commit('user/SET_USER_NAME', '')
				this.commit('user/SET_USER_BIRTHDAY', '')
				this.commit('user/SET_USER_SEX_ID', '')
				this.commit('user/SET_USER_SEX_NAME', '')
				this.commit('user/SET_EDUCATION_ID', '')
				this.commit('user/SET_EDUCATION_NAME', '')
				this.commit('user/SET_EXPECT_POSTS_TYPE_ID', '')
				this.commit('user/SET_EXPECT_POSTS_TYPE_NAME', '')
				this.commit('user/SET_PHOTO_URL', '')
				this.commit('user/SET_PHONE', '')
				this.commit('user/SET_ADMIN_NAME', '')
				this.commit('user/SET_JOIN_IN_STATUS', 0)
				this.commit('user/SET_LOGOUT_STATUS', 0)
				this.commit('user/SET_POST_NAME', '')
				this.commit('user/SET_ENTERPRISE_ID', 0)
				this.commit('user/SET_ENTERPRISE_NAME', '')
				this.commit('user/SET_ENTERPRISE_LOGO_URL', '')
				this.commit('user/SET_ENTERPRISE_ADDRESS', '')
				this.commit('user/SET_ENTERPRISE_PROVINCE', '')
				this.commit('user/SET_ENTERPRISE_CITY', '')
				this.commit('user/SET_ENTERPRISE_AREA', '')
				this.commit('user/SET_IS_SOCIAL_ORGANIZATION', 0)
				Notification.success('退出成功 !')
				resolve()
			}).catch(error => {
				reject(error)
			})
		})
	},

	// 通过ip地址获取用户所在地区
	getUserRegionByIp({state}) {
		return new Promise((resolve, reject) => {
			if (state.user_city) {
				resolve()
				return
			}
			getUserRegionByIp().then(({data}) => {
				this.commit('user/SET_USER_PROVINCE', data.data.province)
				this.commit('user/SET_USER_CITY', data.data.city)
				this.commit('user/SET_USER_AREA', data.data.area)
				resolve(data.data)
			})
		})
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}
