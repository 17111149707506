<template>
  <div class="global-loading la-2x">
    <div></div>
    <div></div>
    <div></div>
  </div>
</template>

<script>
export default {
	name: "my-loading"
}
</script>

<style lang="scss" scoped>
.global-loading {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 0;
  color: #fbb;

  & > div {
    position: relative;
    box-sizing: border-box;
  }

  &.la-dark {
    color: #333;
  }

  &.la-sm > div {
    width: 4px;
    height: 4px;
    margin: 2px;
  }

  &.la-3x > div {
    width: 30px;
    height: 30px;
    margin: 12px;
  }

  &.la-2x > div {
    width: 15px;
    height: 15px;
    margin: 6px;
  }

  & > div {
    display: inline-block;
    float: none;
    width: 10px;
    height: 10px;
    margin: 4px;
    border-radius: 100%;
    animation: ball-pulse 1s ease infinite;
    background-color: currentColor;
    border: 0 solid currentColor;

    &:nth-child(1) {
      animation-delay: -200ms;
    }

    &:nth-child(2) {
      animation-delay: -100ms;
    }

    &:nth-child(3) {
      animation-delay: 0ms;
    }

    @keyframes ball-pulse {
      0%,
      60%,
      100% {
        opacity: 1;
        transform: scale(1);
      }

      30% {
        opacity: 0.1;
        transform: scale(0.01);
      }
    }
  }

}
</style>
