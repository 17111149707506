<template>
  <div class="city-nav">
    <div class="city-nav-wrap">
      <div class="all-city">工作地区：</div>
      <ul class="city-nav-list">
        <li :class="['city-nav-item', {'active': city === ''}]" @click="resetCity">不限</li>
        <li :class="['city-nav-item', {'active': city !== '' && city.includes(item)}]" @click="selectCity(item)"
            v-for="(item,index) of portionHotCityList" :key="index">{{ item }}</li>
        <li class="omit-symbol">...</li>
      </ul>
    </div>
    <div class="spread-all-city" @click="toCityPage">
      <span>全部区域</span>
      <svg-icon icon-class="icon_right_more" class-name="bottom-arrow" />
    </div>
  </div>
</template>

<script>
import hotCityList from "@/assets/json/hotCity"
export default {
	name: "selectCity",
  props: {
		city: {
			type: String,
      default: ''
    }
  },

	data() {
		return {
			hotCityList
		}
	},

	computed: {
		// 展示16个热门城市
		portionHotCityList() {
			return this.hotCityList.slice(0, 16)
		},
	},

	methods: {

		// 选择城市
		selectCity(city) {
			this.$emit('change', `${city}市`)
		},

    // 重置选择城市
		resetCity() {
			this.$emit('change', '')
    },

		// 跳转城市选择页面
		toCityPage() {
			this.$router.push('/city')
		},

	},
}
</script>

<style lang="scss" scoped>
  .city-nav {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    border-bottom: 1px solid #F1F1F1;

    .city-nav-wrap {
      display: flex;
      width: calc(100% - 6em);

      .all-city {
        width: 100px;
        height: 30px;
        color: #666;
        line-height: 30px;
        font-size: 16px;
      }

      .city-nav-list {
        width: calc(100% - 100px);
        @include flexWrap();

        .city-nav-item {
          $height: 30px;
          height: $height;
          padding: 0 8px;
          margin-right: 5px;
          color: #000;
          font-size: 16px;
          line-height: $height;
          cursor: pointer;
          border-radius: 6px;
          transition: all .15s;
          &.active {
            background-color: #ffece9;
            color: $theme;
          }
          &:hover {
            color: $theme;
          }
        }

        .omit-symbol {
          height: 30px;
          line-height: 30px;
        }
      }
    }

    .spread-all-city {
      @include flex(center, center);
      color: #FF4328;
      cursor: pointer;
      .bottom-arrow {
        margin-left: 5px;
        font-size: 12px;
      }
    }
  }
</style>
