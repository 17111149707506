<template>
  <main class="main-wrapper">
    <common-header/>
    <section class="app-main">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive" :key="route"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive" :key="route"></router-view>
    </section>
    <common-footer v-if="!hasFooter"/>
  </main>
</template>

<script>
import commonHeader from "./header/header"
import commonFooter from "./footer/footer"

export default {
  name: "layout",
  components: {commonHeader, commonFooter},
  data() {
    return {
      scrollTop: 0
    }
  },
  computed: {
    route() {
      return this.$route.path
    },

    hasFooter() {
      /* '/activity' */
      const pathList = ['/information', '/login', '/person/person']
      return pathList.includes(this.route)
    },
  },

  watch: {
    "$route.path"(newVal, oldVal) {
      const app = document.getElementById('app')
      if (oldVal !== '/enterprise/detail' && oldVal !== '/post/detail') {
        this.scrollTop = app.scrollTop
        app.scrollTo(0, 0)
      } else {
        setTimeout(() => {app.scrollTop = this.scrollTop},0)
      }
    },
  },

  mounted() {
  },
}
</script>

<style lang="scss" scoped>
.main-wrapper {
  @include flexDirection();
  min-width: 100%;
  min-height: 100vh;

  .app-main {
    flex: 1;
    min-width: $max-width;
  }
}
</style>
