import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/layout'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		redirect: '/home'
	},

	{
		path: '*',
		redirect: '/404'
	},

	{
		path: '/404',
		component: () => import('@/views/unknown/unknown')
	},

	{ // 首页
		path: '/home',
		component: Layout,
		children: [
			{
				path: '',
				name: 'home',
				component: () => import('@/views/home/home'),
				meta: {keepAlive: true},
			}
		]
	},

	{ // 登录页
		path: '/login',
		component: Layout,
		children: [
			{
				path: '',
				name: 'login',
				component: () => import('@/views/login/login')
			}
		]
	},

	{ // 企业入驻页
		path: '/information',
		component: Layout,
		children: [
			{
				path: '',
				name: 'information',
				component: () => import('@/views/login/information'),
			}
		]
	},

	{ // 活动页
		path: '/activity',
		component: Layout,
		children: [
			{
				path: '',
				name: 'activity',
				component: () => import('@/views/activity/activity'),
			}
		]
	},

	{ // 职位页
		path: '/post',
		component: Layout,
		children: [
			{
				path: '',
				name: 'post',
				component: () => import('@/views/post/post'),
				meta: {keepAlive: true},
			},
			{
				path: 'detail',
				name: 'post_detail',
				component: () => import('@/views/post/detail'),
			},
		]
	},

	{ // 企业
		path: '/enterprise',
		component: Layout,
		redirect: '/enterprise/enterprise',
		children: [
			{
				path: '',
				name: 'enterprise',
				component: () => import('@/views/enterprise/enterprise'),
				meta: {keepAlive: true},
			},
			{
				path: 'detail',
				name: 'enterprise_detail',
				component: () => import('@/views/enterprise/detail'),
			},
			{
				path: 'photo_album',
				name: 'enterprise_photo_album',
				component: () => import('@/views/enterprise/photo-album.vue'),
			},
		]
	},

	{
		path: '/person',
		component: Layout,
		redirect: '/person/collect',
		children: [
			{
				path: 'person',
				name: 'person',
				component: () => import('@/views/user/person')
			},
			{
				path: 'collect',
				name: 'collect',
				component: () => import('@/views/user/collect')
			},
			{
				path: 'record',
				name: 'record',
				component: () => import('@/views/user/record')
			},
			{
				path: 'resume',
				name: 'resume',
				component: () => import('@/views/user/resume')
			},
		],
	},

	{
		path: '/city',
		component: Layout,
		children: [
			{
				path: '',
				component: () => import('@/views/city/city')
			}
		]
	},

	{
		path: '/live',
		component: Layout,
		children: [
			{
				path: '',
				component: () => import('@/views/live/live')
			},
			{
				path: 'detail',
				component: () => import('@/views/live/detail')
			}
		]
	},

	{
		path: '/expect',
		component: Layout,
		children: [
			{
				path: 'collect',
				component: () => import('@/views/expect/expect')
			},
			{
				path: 'record',
				component: () => import('@/views/expect/expect')
			},
			{
				path: 'resume',
				component: () => import('@/views/expect/expect')
			},

		]
	}

]

const scrollBehavior = function (to, from, savedPosition) {
   if (savedPosition && to.meta.keepAlive) {
		return savedPosition
	  } else {
		// 如果不是通过上述行为切换组件，就会让页面回到顶部
		return {x: 0, y: 0}
	}
}


const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior
})

export default router
