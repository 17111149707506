<template>
  <header>
    <div class="header-container">
      <nav class="header-left">
        <h1 class="header-inner-logo" @click="toHomePage">
          <img class="logo-image" src="../../assets/images/main-logo.jpg" alt="勇往职前logo"/>
<!--          <strong v-if="isLoginPage || isInformationPage">省人协招聘</strong>-->
        </h1>

        <div :class="['header__city', {hide: !currentArea}]" @click="toCityPage">
          <svg-icon icon-class="icon_address" class-name="address-icon" />
          <span class="header__city_name">{{ currentArea || '神州区' }}</span>
        </div>

        <ul class="nav-list" v-if="isLoginPage === false && isInformationPage === false">
          <li class="nav-item">
            <router-link :class="['nav-link', {'active': route === '/home'}]" to="/home">首页</router-link>
          </li>
          <li class="nav-item">
            <router-link :class="['nav-link', {'active': route === '/post'}]" to="/post">职位</router-link>
          </li>
          <li class="nav-item">
            <router-link :class="['nav-link', {'active': route === '/enterprise'}]" to="/enterprise">公司</router-link>
          </li>
        </ul>
      </nav>

      <div class="header-right" v-if="isLoginPage === false && isInformationPage === false && isPersonPage === false">

        <!-- 没登录 -->
        <div class="is-not-login" v-if="Auth.isLogin() === false">
          <button class="navigate-btn">
            <a class="navigate-link" @click.prevent="handleLogin">我是求职者</a>
          </button>
        </div>

        <!-- 登录了  -->
        <div class="userinfo-head" v-else-if="Auth.isLogin()">
          <el-image class="user-avatar" :src="photo_url" alt="用户头像">
            <div slot="error" class="userinfo-avatar">
              <svg-icon icon-class="user" class-name="svg-icon" />
            </div>
          </el-image>


          <div class="userinfo-menu">
            <div class="userinfo-menu-wrap">
              <div class="userinfo-main" title="完善个人信息" @click="toEditDetailPage">
                <el-image class="user-avatar" :src="photo_url" alt="用户头像">
                  <div slot="error" class="userinfo-avatar">
                    <svg-icon icon-class="user" class-name="svg-icon" />
                  </div>
                </el-image>
                <div class="userinfo-name">
                  <span class="username">{{ user_name || admin_name || '-' }}</span>
                </div>
                <div class="userinfo-phone">{{ phone }}</div>
              </div>

              <div class="enterprise" :title="enterprise_name" v-if="enterprise_name">
                {{ enterprise_name || '' }}
              </div>

              <ul class="userinfo-menu-list">
                <li class="userinfo-menu-item" @click="toExpectPage('/person/resume')">
                  <svg-icon icon-class="icon_my_resume" class-name="svg-icon" />
                  <span>我的简历</span>
                </li>
                <li class="userinfo-menu-item" @click="toExpectPage('/person/record')">
                  <svg-icon icon-class="icon_my_record" class-name="svg-icon" />
                  <span>投递记录</span>
                </li>
                <li class="userinfo-menu-item" @click="toExpectPage('/person/collect')">
                  <svg-icon icon-class="icon_my_collect" class-name="svg-icon" />
                  <span>我的收藏</span>
                </li>
                <li class="userinfo-menu-item" @click="loginOut">
                  <svg-icon icon-class="icon_my_quit" class-name="svg-icon" />
                  <span>退出登录</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        
        <button class="navigate-btn">
          <a class="navigate-link" :href="GOV_ADMIN_URL">我是登记管理机关</a>
        </button>

        <button class="navigate-btn">
          <a class="navigate-link" :href="ENT_ADMIN_URL" @click.prevent="toEnterprise">我是社会组织/企业</a>
        </button>
      </div>
    </div>
  </header>
</template>

<script>
import {mapGetters} from "vuex";
import {ENT_ADMIN_URL, GOV_ADMIN_URL} from '@/config/config.js'
import {Auth} from "@/utils/auth.js";

export default {
	name: "common-header",

	data() {
		return {
			Auth,
			GOV_ADMIN_URL,
			ENT_ADMIN_URL,
			showLogin: false,
		}
	},

	computed: {
		...mapGetters([
			"user_name",
			"admin_name",
			"post_name",
			"phone",
			"token",
			"photo_url",
			'user_city',
			'user_province',
			"enterprise_name",
			"join_in_status"
		]),

		route() {
			return this.$route.path
		},

    // 当前页面是否为 登录页
    isLoginPage() {
			return this.route === '/login'
    },

    // 当前页面是否为 企业入驻页
    isInformationPage() {
			return this.route === '/information'
    },

    // 当前页面是否为 活动页
    isActivityPage() {
			return this.route === '/activity'
    },

    // 当前页面是否为 完善个人信息页
    isPersonPage() {
			return this.route === '/person/person'
    },

    // 当前地区
    currentArea() {
			if (this.user_city === '0') return this.user_province
      return this.user_city
    },
	},

	async mounted() {
		// 获取用户所在地区
		this.$store.dispatch('user/getUserRegionByIp')

		if (Auth.isLogin()) {
			// 获取普通用户信息
      this.isPersonPage || this.$store.dispatch('user/getUserInfo')
      // 获取企业用户信息
			await this.$store.dispatch('user/getEntUserInfo')
      // 获取企业详情
			this.$store.dispatch('user/getEnterpriseDetail')
		}
	},

	methods: {

		toHomePage() {
			if (this.route === '/home') return
			this.$router.replace('/')
    },

    // 跳转到登录页
    handleLogin() {
			if (this.phone) {
				this.$router.push('/information')
      } else {
				this.isActivityPage && this.$store.commit('app/SET_IS_ACTIVITY_TO_LOGIN', true)
				this.$router.push('/login')
      }
    },

		// 跳转企业端
		toEnterprise() {
			if (Auth.isLogin()) {
				// 0:未入驻 | 1:入驻通过 | 2:入驻审核中
        if (this.join_in_status === 0) {
					// 以防在登录页点击按钮报错
	        this.isInformationPage || this.$router.push('/information')
          return
        }

				if (this.join_in_status === 1) {
					window.open(this.ENT_ADMIN_URL, '_self')
          return
				}

				if (this.join_in_status === 2) {
					// 以防在入驻页点击按钮报错
					this.isInformationPage
            ? this.$message.warning('请等待审核结果 ~')
            : this.$router.push('/information')
          return
        }

				if (this.join_in_status === 3) {
					// 以防在入驻页点击按钮报错
					this.isInformationPage
						? this.$message.warning('请等待审核结果 ~')
						: this.$router.push('/information')
				}
      } else {
				this.$store.commit('app/SET_IS_TAP_ENT_TO_LOGIN', true)
				this.isLoginPage || this.$router.push('/login')
      }
    },


    // 名字太长用省略号
		formatEllipsisText(text) {
			if (typeof text !== 'string' || text === '') {
				return ''
      }
			return text.substring(0, 5) + '...'
    },


		// 退出登录
		async loginOut() {
      await this.$store.dispatch('user/logout')
      // 如果在企业入驻页面点击了 退出登录
      if (this.isInformationPage) {
	      this.$router.replace('/home')
      }
		},


    // 跳转到城市页
    toCityPage() {
			this.$router.push('/city')
    },


    // 跳转到收藏列表页
		toCollectPage() {
			this.$router.push('/person/collect')
    },

    // 调到敬请期待页面
    toExpectPage(path) {
	    if (this.$route.path === path) return
      this.$router.push(path)
    },

    // 调到完善个人信息页面
		toEditDetailPage() {
			if (this.isPersonPage) return
			this.$router.push('/person/person')
    },
	},
}
</script>

<style lang="scss" scoped>
header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  min-width: $max-width;
  width: 100%;
  height: 80px;
  background-color: #FFF;
  padding: 5px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);

  .abc {
    font-size: 80px;
    fill: blue;
  }

  .header-container {
    width: 1205px;
    min-width: $header-width;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    margin: 0 auto;

    .header-left {
      display: flex;
      align-items: center;

      .header-inner-logo {
        @include flex(normal, center);
        cursor: pointer;
        .logo-image {
          display: block;
          width: 140px;
          height: 60px;
          object-fit: cover;
        }
        strong {
          font-weight: 500;
          margin-left: 20px;
          font-size: 20px;
        }
      }

      .header__city {
        padding: 0 20px;
        font-size: 16px;
        transition: color .15s;
        cursor: pointer;

        &.hide {
          opacity: 0;
        }
        &:hover {
          color: $theme;
        }
        .address-icon {
          font-size: 16px;
          margin-right: 3px;
        }
      }

      .nav-list {
        display: flex;
        font-size: 16px;
        font-weight: bold;
        .nav-item {
          padding: 0.5rem 1rem;

          .nav-link {
            padding: 0;
            border-bottom: 3px solid transparent;
            &.active {
              border-bottom-color: #ff4328;
            }
            &:hover {
              color: #333333;
            }
          }
        }
      }

    }

    .header-right {
      @include flex(normal, center);

      .userinfo-head {
        position: relative;
        margin-left: 15px;

        .user-avatar {
          @include image(44px, 44px, cover);
          border-radius: 50%;
          cursor: pointer;
        }

        ::v-deep .userinfo-avatar {
          @include flex(center, center);
          width: 100%;
          height: 100%;
          border: 1px solid #f2f3f5;
          border-radius: 50%;
          & > .svg-icon {
            color: #ccc;
            font-size: 32px;
          }
        }

        &:hover .userinfo-menu {
          opacity: 1;
          transform: translateX(-50%) scaleY(1);
        }

        .userinfo-menu {
          position: absolute;
          top: 100%;
          left: 50%;
          padding-top: 25px;
          opacity: 0;
          transition-property: transform, opacity;
          transition-duration: .15s;
          transition-delay: .15s;
          transform-origin: 50% 0;
          transform: translateX(-50%) scaleY(0);

          .userinfo-menu-wrap {
            width: 320px;
            padding: 10px 30px;
            background-color: #fff;
            box-shadow: 0 6px 10px 0 rgba(0,0,0,0.1);
            border-radius: 4px;
            .userinfo-main {
              @include flex(normal, center);
              padding: 20px 0 10px;
              cursor: pointer;

              .userinfo-name {
                @include flexDirection(center);
                margin-left: 10px;
                .username {
                  font-size: 16px;
                  font-weight: 500;
                  color: #000;
                }
              }

              .userinfo-phone {
                margin-left: auto;
                font-size: 16px;
                color: #666;
              }
            }

            .enterprise {
              margin-bottom: 12px;
              font-size: 14px;
              color: #000;
            }

            .userinfo-menu-list {
              //padding-left: 10px;
              .userinfo-menu-item {
                @include flex(normal, center);
                padding: 15px 10px;
                font-size: 16px;
                color: #000;
                white-space: nowrap;
                border-top: 1px solid #F1F1F1;
                cursor: pointer;
                transition: all .15s;
                &:hover {
                  color: $theme;
                  .svg-icon {
                    fill: $theme;
                    stroke: $theme;
                  }
                }

                .svg-icon {
                  margin-right: 8px;
                  font-size: 24px;
                  fill: #000;
                  stroke: #000;
                  transition: all .15s;
                }
              }
            }
          }
        }
      }

      .is-not-login {
        @include flex();
      }

      .navigate-btn {
        height: 40px;
        margin-left: 10px;
        background-color: #FFF;
        font-size: 16px;
        color: $theme;
        line-height: 40px;
        border: 1px solid $theme;
        border-radius: 50px;
        cursor: pointer;
        transition: all .3s;
        &:hover {
          color: #FFFFFF;
          background-color: #ff4328;
        }
        .navigate-link {
          display: block;
          width: 100%;
          height: 38px;
          line-height: 38px;
          padding: 0 15px;
        }
      }

    }
  }
}


</style>
