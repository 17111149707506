import router from './index'
import store from '../store'
import { Message } from 'element-ui'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { Auth } from '@/utils/auth'
import {isMobile, objToParams} from '@/utils/utils'
import {MOBILE_REDIRECT_URL} from '@/config/config'
import loading from "@/components/loading/loading.vue";

NProgress.configure({ showSpinner: false }) // NProgress Configuration

// 不需要携带token即可访问的路由组
const whiteList = [
	'/activity',
	'/home',
	'/post',
	'/post/detail',
	'/login',
	'/city',
	'/enterprise',
	'/enterprise/detail',
	'/enterprise/photo_album',
	'/test',
	'/expect',
	'/live',
	'/live/detail',
	'/404'
]

router.beforeEach(async(to, from, next) => {

	NProgress.start()

	const hasToken = Auth.getToken()
	const joinStatus = store.getters.join_in_status

	if (isMobile()) {
		if (to.path === '/activity') {
			const obj = { activity_id: to.query.id || '', share_code: to.query.shareCode || '' }
			const params = objToParams(obj)
			window.open(MOBILE_REDIRECT_URL + `?${params}`, '_self')
			
			return
		}
		window.open(MOBILE_REDIRECT_URL, '_self')
		next()
		return
	}

	if (hasToken) {
		if (to.path === '/login') {
			next('/home')
			NProgress.done()
		} else if (to.path === '/information') {

			joinStatus === 1 ? next('/home') : next()
			NProgress.done()

		} else {
			const hasGetUserInfo = store.getters.join_in_status
			// console.log(hasGetUserInfo,joinStatus,'666')

			/*if (hasGetUserInfo && joinStatus===1) {
				// historyKeyword.save(to.meta.title,to.fullPath)
				const fatherMenuPermission = store.getters.fatherMenuPermission || []
				const childrenMenuPermission = store.getters.childrenMenuPermission || []

				if (to && to.meta && to.meta.authFlag) {
					// console.log(to, "to")

					if (fatherMenuPermission.includes(to.meta.authFlag) || childrenMenuPermission.includes(to.meta.authFlag)) {
						next()
					} else {
						const routes = router.options.routes
						const _result = routes.find(item => {
							if (item.meta) {
								return item.meta.authFlag === fatherMenuPermission[0]
							}
						})
						if (_result) {
							next(`${_result.path}/${_result.children[0].path}`)
							NProgress.done()
							return
						}

						next('/403')
						NProgress.done()
					}
				} else {
					next();
				}
			} else {
				try {
					// historyKeyword.save(to.meta.title,to.fullPath)
					// get user info
					await store.dispatch('user/getInfo').then(() => {
						if (store.getters.join_in_status!==1){
							// window.open(OFFICE_LOCATION_URL, '_self')
							next(`/login?redirect=${to.path}`)
							NProgress.done()
							return
						}
						const fatherMenuPermission = store.getters.fatherMenuPermission || []
						const childrenMenuPermission = store.getters.childrenMenuPermission || []
						if (to && to.meta && to.meta.authFlag) {
							if (fatherMenuPermission.includes(to.meta.authFlag) || childrenMenuPermission.includes(to.meta.authFlag)) {
								next()
							} else {

								const routes = router.options.routes
								const _result = routes.find(item => {
									if (item.meta) {
										return item.meta.authFlag === fatherMenuPermission[0]
									}
								})

								if (_result) {
									next(`${_result.path}/${_result.children[0].path}`)
									NProgress.done()
									return
								}

								next(`/403`)
							}
						} else {
							next();
						}
					})
				} catch (error) {
					// remove token and go to login page to re-login
					// await store.dispatch('user/resetToken')
					Message.error(error || 'Has Error')
					NProgress.done()
				}
			}*/

			next()

		}
	} else {
		if (whiteList.includes(to.path)) {
			next()
		} else {
			next('/home')
			NProgress.done()
		}
	}
})

router.afterEach(() => {
	NProgress.done()
})
