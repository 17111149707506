<template>
  <div class="home-search-box">
    <div class="home-search" @click.stop>
      <input class="search-input" autocomplete="new-password"
             :value="value" type="text"
             placeholder="搜索岗位/企业" @input="onInput"
             @keyup.enter="onSearch(value)"
             @change="onChange"
             @focus="onFocus" />
      <button class="search-btn" @click="onSearch(value)">搜索</button>
    </div>

    <transition name="search">
      <!-- v-if="(searchMainSub && searchKeywordList.length) || (searchMainSub && hotKeywordList.length)" -->
      <div class="search-result-wrapper" @click.stop
           v-if="(searchMainSub && searchKeywordList.length)"
      >
        <div class="history-search" v-if="searchKeywordList.length">
          <div class="history-search__title">
            <span>历史搜索</span>
            <span class="clear-history-search" @click="clearSearchHistory">清空历史</span>
          </div>
          <ul class="history-search__list">
            <li class="history-search__item" :key="item.id" @click="onSearch(item.keyword)"
                v-for="item of searchKeywordList" >{{ item.keyword }}</li>
          </ul>
        </div>
<!--        <div class="hot-search">
          <div class="hot-search__title">
            <span>热门搜索</span>
          </div>
          <ul class="hot-search__list">
            <li class="hot-search__item" :key="item.id" @click="onSearch(item.keyword)"
                v-for="item of hotKeywordList">{{ item.keyword }}</li>
          </ul>
        </div>-->
      </div>
    </transition>
  </div>
</template>

<script>

export default {
	name: "search",
  props: {
		placeholder: {
			type: String,
      default: '',
    },
    value: {
	    type: String,
	    default: '',
    },
    type: {
			type: String,
      default: 'post'
    }
  },
	data() {
		return {
			searchMainSub: false,
			hotKeywordList: [],
			searchKeywordList: [],
		}
	},

	mounted() {
		this.initData()
	},

	methods: {

		initData() {
			if (this.type === 'post') {
				this.getHotKeywords()
				this.getSearchHistory()
			} else if (this.type === 'enterprise') {
				this.getEntHotKeywords()
				this.getSearchEntHistory()
			}
    },

		globalClick() {
			this.searchMainSub = false
      this.cancelGlobalClick()
		},

    cancelGlobalClick() {
			this.searchMainSub = false
	    document.removeEventListener('click', this.globalClick)
    },

		onInput(event) {
			this.$emit('input', event.target.value)
			this.searchMainSub = !event.target.value
		},

    onChange(event) {
	    this.searchMainSub = !event.target.value
    },

		onFocus() {
			document.addEventListener('click', this.globalClick)
			if (this.value) return
			this.searchMainSub = true
		},

		onSearch(keyword) {
			this.$emit('input', keyword)
			const query = keyword || this.value || ''
      this.$emit('search', query)
      this.searchMainSub = false
      setTimeout(() => {
	      this.initData()
      }, 300)
		},

		// 热门搜索关键字
		async getHotKeywords() {
			const {data} = await this.$api.getHotKeywords()
			if (data.level === 'success') {
				this.hotKeywordList = data.data
			}
		},

		// 热门搜索关键字
		async getEntHotKeywords() {
			const {data} = await this.$api.getEntHotKeywords()
			if (data.level === 'success') {
				this.hotKeywordList = data.data
			}
		},

		// 用户搜索记录
		async getSearchHistory() {
			const {data} = await this.$api.getSearchHistory()
			if (data.level === 'success') {
				this.searchKeywordList = data.data
			}
		},

		// 用户搜索记录
		async getSearchEntHistory() {
			const {data} = await this.$api.getSearchEntHistory()
			if (data.level === 'success') {
				this.searchKeywordList = data.data
			}
		},

		// 清除用户搜索记录
		clearSearchHistory() {
			if (this.type === 'post') this.clearPostSearchHistory()
      if (this.type === 'enterprise') this.clearSearchEntHistory()
		},

		// 清除用户搜索记录
		async clearPostSearchHistory() {
			const {data} = await this.$api.clearSearchHistory()
			if (data.level === 'success') {
				this.searchMainSub = false
				this.getSearchHistory()
			}
		},

		// 清除用户搜索记录
		async clearSearchEntHistory() {
			const {data} = await this.$api.clearSearchEntHistory()
			if (data.level === 'success') {
				this.searchMainSub = false
				this.getSearchEntHistory()
			}
		},
	},
}
</script>

<style lang="scss" scoped>
  .home-search-box {
    position: relative;
    padding-top: 20px;
    .home-search {
      $borderRadius: 6px;
      @include flex();
      width: 900px;
      height: 52px;
      padding: 2px;
      margin: 0 auto;
      background-color: $theme;
      border-radius: $borderRadius;
      .search-input {
        width: 800px;
        height: 100%;
        background-color: #fff;
        text-align: center;
        border-radius: $borderRadius;
        outline: none;
        border: none;
      }

      .search-btn {
        width: 100px;
        height: 100%;
        background-color: $theme;
        color: #fff;
        text-align: center;
        line-height: 48px;
        font-size: 16px;
        font-weight: 500;
      }
    }

    .search-result-wrapper {
      //display: none;
      position: absolute;
      z-index: 11;
      top: calc(100% + 10px);
      left: calc((100vw - 900px) / 2);
      width: 790px;
      padding: 15px;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0 4px 16px 0 rgba(0,0,0,.08);
      overflow: hidden;

      .history-search {
        //margin-bottom: 30px;
        .history-search__title {
          @include flex(space-between);
          font-size: 14px;
          color: #999;

          .clear-history-search {
            cursor: pointer;
            transition: color .15s;
            &:hover {
              color: $theme;
            }
          }
        }
        .history-search__list {
          @include flexWrap();
          .history-search__item {
            height: 25px;
            padding: 0 5px;
            margin-top: 15px;
            margin-right: 15px;
            background-color: #f6f6f6;
            line-height: 25px;
            font-size: 14px;
            color: #333;
            border-radius: 5px;
            cursor: pointer;
            transition: all .15s;
            &:hover {
              background-color: #ffece9;
              color: $theme;
            }
          }
        }
      }

      .hot-search {
        .hot-search__title {
          @include flex(space-between);
          font-size: 14px;
          color: #999;

          .clear-hot-search {
            cursor: pointer;
            transition: color .15s;
            &:hover {
              color: $theme;
            }
          }
        }

        .hot-search__list {
          @include flexWrap();
          .hot-search__item {
            height: 25px;
            padding: 0 5px;
            margin-top: 15px;
            margin-right: 15px;
            background-color: #f6f6f6;
            line-height: 25px;
            font-size: 14px;
            color: #333;
            border-radius: 5px;
            cursor: pointer;
            transition: all .15s;
            &:hover {
              background-color: #ffece9;
              color: $theme;
            }
          }
        }
      }
    }
  }

  .search-leave-active,
  .search-enter-active {
    transform-origin: 50% 0;
    transition: all .3s;
  }

  .search-enter,
  .search-leave-to {
    opacity: 0;
    transform: translateY(-30px) scaleY(0);
  }

  .search-leave,
  .search-enter-to {
    opacity: 1;
    transform: translateY(0) scaleY(1);
  }
</style>
