import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui'
import { VueJsonp } from 'vue-jsonp'
import VueClipBoard from 'vue-clipboard2'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'

import '@/icons'
import '@/router/permission'
import customImage from '@/components/customImage/image'
import onSearch from '@/components/search/search'
import loading from '@/components/loading/loading'
import selectCity from '@/components/selectCity/selectCity'
import nativeSearch from '@/components/nativeSearch/native-search'

import myLoading from '@/directive/loading'

import * as api from './api'

import 'normalize.css/normalize.css'
import 'animate.css'
import '@/styles/index.scss'


Vue.config.productionTip = false
Vue.directive('my-loading', myLoading)
Vue.component('elImageViewer', ElImageViewer)
Vue.component('customImage', customImage)
Vue.component('onSearch', onSearch)
Vue.component('loading', loading)
Vue.component('selectCity', selectCity)
Vue.component('nativeSearch', nativeSearch)
Vue.use(ElementUI)
Vue.use(VueJsonp)
Vue.use(VueClipBoard)

Vue.prototype.$api = api;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
