<template>
  <div :class="['search-input', {'is-focus': isFocus}]"
       :style="{'width': Number(width) ? width + 'px' : width }">
    <input :value="value"
           :disabled="disabled"
           class="form-input" type="text"
           @input="onInput"
           @focus="onFocus"
           @blur="onBlur"
           @keyup.enter="onSearch"
           :placeholder="placeholder" />
    <div class="close-btn">
      <i :class="['el-icon-circle-close', value !== '' ? 'show' : 'hide']"
         @click="clearInput" />
    </div>

    <svg-icon icon-class="icon-search" class-name="svg-icon" @click="onSearch" />
  </div>
</template>

<script>
export default {
	name: "native-search",
  props: {
		value: {
			type: String,
      default: '',
      required: true
    },
		width: {
			type: [Number, String],
      default: 280,
    },
	  placeholder: {
		  type: String,
		  default: '请输入内容'
    },
    disabled: {
			type: Boolean,
      default: false,
    },
    icon: {
			type: String,
      default: 'icon-search'
    },
  },

	data() {
		return {
			keyword: '',
      isFocus: false,
		}
	},

	methods: {

		onInput(event) {
			this.$emit('input', event.target.value)
    },

		clearInput() {
			this.$emit('input', '')
      this.onSearch()
		},

    onSearch() {
			this.$emit('search', this.value)
    },

    onFocus() {
			this.isFocus = true
    },

    onBlur() {
			this.isFocus = false
    }

	},
}
</script>

<style lang="scss" scoped>
  .search-input {
    width: 275px;
    min-width: 200px;
    @include flex(space-between, center);
    display: inline-flex;
    padding: 8px 15px;
    background-color: #f8f8f8;
    border-radius: 40px;
    border: 1px solid transparent;
    transition: border-color .3s;
    &.is-focus {
      border-color: #dcdfe6;
    }

    .svg-icon {
      font-size: 16px;
      cursor: pointer;
    }

    .close-btn {
      @include flex(center, center);
      height: 100%;
      padding: 0 10px 0 6px;
      .el-icon-circle-close {
        font-size: 14px;
        color: #c0c4cc;
        cursor: pointer;
        transition: all .15s;
        &:hover {
          color: #909399;
        }
        &.hide {
          opacity: 0;
          pointer-events: none;
        }
      }
    }



    .form-input {
      width: 90%;
      background-color: transparent;
      color: #606366;
      font-size: 14px;
      outline: none;
      border: 0;

      &::-webkit-input-placeholder{
        font-size: 14px;
        color: #999;
      }
      &::-moz-placeholder{   /* Mozilla Firefox 19+ */
        color: #999;
        font-size: 14px;
      }
      &:-moz-placeholder{    /* Mozilla Firefox 4 to 18 */
        color: #999;
        font-size: 14px;
      }
      &:-ms-input-placeholder{  /* Internet Explorer 10-11 */
        color: #999;
        font-size: 14px;
      }
    }
  }
</style>
