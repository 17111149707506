// 用户服务协议
const SERVICE_AGREEMENT_LINK = 'https://rxzp-file.gdhrma.com/service_agreement.docx'
// 第三方预览地址
const PREVIEW_LINK = 'http://file-preview.rzkeji.com/onlinePreview?url='
// 普通企业默认头像
const DEFAULT_ENTERPRISE_AVATAR = 'https://rxzp-file.gdhrma.com/default_logo1.png'
// 社会组织默认头像
const DEFAULT_ORGAN_AVATAR = 'https://rxzp-file.gdhrma.com/default_logo2.png'

const config = (function(env) {
	//服务器地址
	const HTTP_URL = 'https://rxzp-sys.gdhrma.com'
  const HTTP_URL_DEV = 'https://rxzp-sys-dev.dindong.cn'

	const configMap = {
		'development': {
			HTTP_URL: HTTP_URL_DEV,
			API_BASE_URL: HTTP_URL_DEV + '/api/',
			ENT_ADMIN_URL: 'https://ent-dev.dindong.cn',
			COOKIE_DOMAIN: '',
			GOV_ADMIN_URL: 'https://gov-dev.dindong.cn',
			MOBILE_REDIRECT_URL: 'https://rxzp-sys-dev.dindong.cn/jumpMiniProgram/YBADCC'
		},
		'test-development': {
			HTTP_URL: HTTP_URL_DEV,
			API_BASE_URL: HTTP_URL_DEV + '/api/',
			ENT_ADMIN_URL: 'https://ent-dev.dindong.cn',
			COOKIE_DOMAIN: '.dindong.cn',
			GOV_ADMIN_URL: 'https://gov-dev.dindong.cn',
			MOBILE_REDIRECT_URL: 'https://rxzp-sys-dev.dindong.cn/jumpMiniProgram/YBADCC'
		},
		'production': {
			HTTP_URL,
			API_BASE_URL: HTTP_URL + '/api/',
			ENT_ADMIN_URL: 'https://ent.gdhrma.com',
			COOKIE_DOMAIN: '.gdhrma.com',
			GOV_ADMIN_URL: 'https://gov.gdhrma.com',
			MOBILE_REDIRECT_URL: 'https://rxzp-sys.gdhrma.com/jumpMiniProgram/YBADCC'
		}
	}
	return configMap[env]
})(process.env.VUE_APP_ENV)


const {
	HTTP_URL,
	API_BASE_URL,
	ENT_ADMIN_URL,
	GOV_ADMIN_URL,
	COOKIE_DOMAIN,
	MOBILE_REDIRECT_URL,
} = config

export {
  HTTP_URL,
  API_BASE_URL,
	GOV_ADMIN_URL,
	ENT_ADMIN_URL,
	COOKIE_DOMAIN,
	SERVICE_AGREEMENT_LINK,
	PREVIEW_LINK,
	MOBILE_REDIRECT_URL,
	DEFAULT_ORGAN_AVATAR,
	DEFAULT_ENTERPRISE_AVATAR,
}
