import axios from '@/utils/request'
import { API_BASE_URL } from '@/config/config'

//上传文件
export const UPLOAD_FILE = API_BASE_URL + 'file/upload-img'
// 新增附件简历
export const setAttachmentResume = API_BASE_URL + "job/resume/setAttachmentResume"

/**
 *  普通用户登录注册
 */
// 手机号码登录注册
export const loginByPhone = params => axios.post("job/auth/loginByPhone", params);
// 发送验证码
export const sendVerificationCode = params => axios.post("job/auth/sendVerificationCode", params);
// 退出登录
export const logout = params => axios.get("job/auth/logout", {params});
// 获取用户信息
export const getUserInfo = params => axios.get("job/auth/getUserInfo", {params});
// 编辑用户信息
export const setUserInfo = params => axios.post("job/auth/setUserInfo", params);
// 通过ip地址获取用户所在地区
export const getUserRegionByIp = params => axios.get("job/auth/getUserRegionByIp", {params});



/**
 *  企业用户登录注册申请入驻
 */
// 企业用户注册登录
export const signInByPhone = params => axios.post("job/entUser/signInByPhone", params);
// 发送验证码
export const sendEntVerificationCode = params => axios.post("job/entUser/sendVerificationCode", params);
// 退出登录
export const loginOut = params => axios.get("job/entUser/loginOut", {params});
// 获取企业用户个人信息
export const getEntUserInfo = params => axios.get("job/entUser/getEntUserInfo", {params});
// 设置企业用户个人信息
export const setEntUserInfo = params => axios.post("job/entUser/setEntUserInfo", params);
// 搜索受邀请的社会组织
export const getSocialOrganizationScreenList = params => axios.get("job/entUser/getSocialOrganizationScreenList", {params});
// 关键词搜索企业
export const searchEnterprise = params => axios.get("job/entUser/searchEnterprise", {params});
// 加入已经创建的企业
export const joinInEnterprise = params => axios.post("job/entUser/joinInEnterprise", params);
// 提交企业入驻申请
export const submitEnterpriseApply = params => axios.post("job/entUser/submitEnterpriseApply", params);
// 获取上一次提交记录
export const getEnterpriseLastTimeApply = params => axios.get("job/entUser/getEnterpriseLastTimeApply", {params});
// 获取创建企业最后一次数据
export const getLastTimeCreatedData = params => axios.get("job/entUser/getLastTimeCreatedData", {params});
// 获取所有行业类型
export const getIndustryScreenList = params => axios.get("job/entUser/getIndustryScreenList", {params});
// 通过社会统一信用代码获取企业类型
export const getEnterpriseType = params => axios.get("job/entUser/getEnterpriseType", {params});


/**
 *    活动
 */
// 获取活动详情
export const getActivityDetail = params => axios.get("job/activity/getActivityDetail", {params});
// 报名参加活动
export const activityApply = params => axios.post("job/activity/activityApply", params);
// 获取参与活动的企业列表
export const getActivityEnterpriseUrl = API_BASE_URL + 'job/activity/getActivityEnterpriseList'
// 获取参与活动的企业的岗位列表
export const getActivityPostsListUrl = API_BASE_URL + "job/activity/getActivityPostsList"
// 活动页获取所有工作类型
export const getActivityPostsTypeScreenListUrl = API_BASE_URL + "job/activity/getPostsTypeScreenList"
// 获取所有行业类型
export const getIndustryScreenListUrl = API_BASE_URL + 'job/activity/getIndustryScreenList'
// 获取报名活动状态
export const getActivityApplyStatus = params => axios.post("job/activity/getActivityApplyStatus", params);
// 邀请已参与活动的企业
export const inviteAssociationEnterprise = params => axios.post("job/activity/inviteAssociationEnterprise", params);
// 获取活动直播间列表
export const getActivityLiveBroadcastRoomList =  API_BASE_URL + "job/activity/getActivityLiveBroadcastRoomList"
// 获取活动直播间详情
export const getActivityLiveBroadcastRoomDetail = params => axios.get("job/activity/getActivityLiveBroadcastRoomDetail", {params});
// 获取直播间绑定的岗位列表
export const getActivityLiveBroadcastRoomBindingPostsListUrl = API_BASE_URL + 'job/activity/getActivityLiveBroadcastRoomBindingPostsList'
// 获取分享链接
export const getInvitationLink = params => axios.get("job/activity/getInvitationLink", {params});
// 获取受邀请企业信息
export const getInviteEnterpriseInfo = params => axios.post("job/entUser/getInviteEnterpriseInfo", params);



/**
 *    首页
 */
// 获取轮播图
export const getBannerList = params => axios.get("job/basic/getBannerList", {params});
// 首页获取岗位类型筛选列表
export const getHomePostsTypeScreenList = params => axios.get("job/index/getPostsTypeScreenList", {params});
// 获取首页岗位列表
export const getIndexPostsListUrl = API_BASE_URL + 'job/index/getIndexPostsList';
// 获取首页企业列表
export const getIndexEnterpriseListUrl = API_BASE_URL + 'job/index/getIndexEnterpriseList';



/**
 *    企业
 */
// 获取企业详情
export const getEnterpriseDetail = params => axios.get("job/enterprise/getEnterpriseDetail", {params});
// 获取企业列表
export const getEnterpriseListUrl = API_BASE_URL + 'job/enterprise/getEnterpriseList'
// 获取所有行业类型
export const getEntIndustryScreenListUrl = API_BASE_URL +  "job/enterprise/getIndustryScreenList"
// 获取企业热门搜索关键字
export const getEntHotKeywords = params => axios.get("job/enterprise/getEntHotKeywords", {params});
// 获取用户搜索企业记录
export const getSearchEntHistory = params => axios.get("job/enterprise/getSearchEntHistory", {params});
// 清除用户搜索企业记录
export const clearSearchEntHistory = params => axios.post("job/enterprise/clearSearchEntHistory", params);



/**
 *    岗位
 */
// 获取所有工作类型
export const getPostsTypeScreenListUrl = API_BASE_URL + 'job/posts/getPostsTypeScreenList'
// 获取岗位列表
export const getPostsListUrl = API_BASE_URL + 'job/posts/getPostsList'
// 获取岗位详情
export const getPostsDetail = params => axios.get("job/posts/getPostsDetail", {params});
// 获取热门搜索关键字
export const getHotKeywords = params => axios.get("job/posts/getHotKeywords", {params});
// 获取用户搜索记录
export const getSearchHistory = params => axios.get("job/posts/getSearchHistory", {params});
// 清除用户搜索记录
export const clearSearchHistory = params => axios.post("job/posts/clearSearchHistory", params);
// 收藏|取消收藏岗位
export const collectPosts = params => axios.post("job/posts/collectPosts", params);
// 获取用户简历列表
export const getUserResumeList = params => axios.get("job/posts/getUserResumeList", {params});
// 获取用户简历详情
export const getUserResumeDetail = params => axios.get("job/posts/getUserResumeDetail", {params});
// 用户投递简历
export const userSendResume = params => axios.post("job/posts/userSendResume", params);
// 获取用户收藏的岗位列表
export const getCollectPostsListUrl = "job/posts/getCollectPostsList"



/**
 *    简历
 */
// 获取在线简历列表
export const getOnlineResumeListUrl =  API_BASE_URL + 'job/resume/getOnlineResumeList'
// 获取所有工作类型
export const getEnterprisePostsTypeUrl = API_BASE_URL + 'job/resume/getEnterprisePostsType'
// 获取用户投递列表
export const getPostsDeliveryListUrl = API_BASE_URL + 'job/resume/getPostsDeliveryList'
// 获取用户投递详情
export const getPostsDeliveryDetail = params => axios.get("job/resume/getPostsDeliveryDetail", {params});
// 获取附件简历列表
export const getAttachmentResumeList = params => axios.get('job/resume/getAttachmentResumeList', {params})

// 获取在线简历详情
export const getOnlineResumeDetail = params => axios.get("job/resume/getOnlineResumeDetail", {params});
// 编辑在线简历
export const setOnlineResume = params => axios.post("job/resume/setOnlineResume", params);
// 删除在线简历
export const delOnlineResume = params => axios.post("job/resume/delOnlineResume", params);
// 删除附件简历
export const delAttachmentResume = params => axios.post("job/resume/delAttachmentResume", params);
