import Cookies from 'js-cookie'
import {COOKIE_DOMAIN} from "@/config/config";

class Auth {

	static token_key = 'rx_secret'

	static request_key = "Authorization"

	static getToken() {
		return Cookies.get(this.token_key) || '';
	}

	static setToken(token) {
		Cookies.set(this.token_key, token, {domain: COOKIE_DOMAIN})
	}

	static removeToken() {
		Cookies.remove(this.token_key, {domain: COOKIE_DOMAIN})
	}

	static isLogin() {
		return !!this.getToken()
	}

}

export {
	Auth
}
