import Vue from 'vue'
import Loading from '@/components/loading/my-loading'

export default {
	inserted(el, binding) {
		const position = window.getComputedStyle(el).getPropertyValue('position')
		if (['fixed', 'absolute', 'relative'].includes(position) === false) {
			el.style.position = 'relative'
		}
		const loadingModule = Vue.extend(Loading)
		const loadingTemplate = new loadingModule().$mount()
		el.instance = loadingTemplate.$el
		if (binding.value) append(el)
	},
	update(el, binding) {
		if (binding.value !== binding.oldValue) {
			binding.value ? append(el) : remove(el)
		}
	}
}

const append = (el) => {
	el.appendChild(el.instance)
}

const remove = (el) => {
	el.removeChild(el.instance)
}
